import React from 'react';
import "../../Assets/css/background.css";
import Particles from 'react-particles-js';

function Background() {
  return (

<div className='bg-body' style={{zIndex:"-10000"}}>

  </div>

  );
}

export default Background;
