import React from 'react';
import image from "../../Assets/Images/Projects/none.png"

function Projects() {
  return (


<projects>
<body> 
  <div className="projects col-lg-4 col-lg-offset-4 col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 col-xs-10 col-xs-offset-1 align-items-center text-justify"  style={{marginTop:"15vh"}}>

  <img src={image} className="center-block" style={{width:"250px", height:"164.47px", marginBottom:"25px"}} />
  If you are seeing this that means I've been procastinating and not actually working on any projects. I don't know why I fall into this habit but I am try to
  get out of it. It is the reason my wife left me. SARAH PLEASE COME BACK!!! Just kidding...I don't have a wife... Or do I? ( ͡° ͜ʖ ͡°)

  </div>

</body>
</projects>


  );
}

export default Projects;
