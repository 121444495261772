import React, {useState} from 'react';
import{
	Link
}from 'react-router-dom';
import "../../Assets/css/header.css";
import pdf from "../../Components/resume/resume.pdf";
import {ThemeProvider, createGlobalStyle} from 'styled-components';


const GlobalStyle = createGlobalStyle`
.homepage-bio{
color:${props=>props.theme.mode==='dark'?'#32e0c4':'#000000'}!important;
}

.bg-body{
background-image:${props=>props.theme.mode==='dark'?'linear-gradient(20deg, #121212, #121212)':'linear-gradient(20deg, #a8dda8, #f6f7d4)'}!important;
}


.blog-content{
color:${props=>props.theme.mode==='dark'?'#32e0c4':'#000000'}!important;
}

.blog-heading{
color:${props=>props.theme.mode==='dark'?'#ff4b5c':'#132743'}!important;
}

.blog-tags{
color:${props=>props.theme.mode==='dark'?'#f638dc':'#0d7377'}!important;
}

.blog-date{
  color:${props=>props.theme.mode==='dark'?'#f638dc':'#0d7377'}!important;
}
  


.blog-hyperlinks{
  color:${props=>props.theme.mode==='dark'?'#ff4b5c':'#132743'}!important;
}

.projects{
  color:${props=>props.theme.mode==='dark'?'#32e0c4':'#132743'}!important;
}

.colorModeLight{
  display:${props=>props.theme.mode==='dark'?'none':'inline'}!important;
}

.colorModeDark{
  display:${props=>props.theme.mode==='dark'?'inline':'none'}!important;
}

@media only screen and (max-width:599px){
.coloModeText{
display:none!important;
}
}

`




function Header() {
  const[theme,setTheme]=useState({mode:"light"});
  return (
<header>
<ThemeProvider theme={theme}>
<>
<GlobalStyle/>
<body className="bodyHeader">
<nav className="navbarc" style={{zIndex:"1000"}}>
    <ul className="navbarc-nav">







      

      <li className="logoc">
     
        <Link to="/" className="navc-link">
          <span className="link-text logoc-text">MAYUR</span>
          <svg
viewBox="0 0 512 512" svg version="1.1" id="Capa_1"
   height="30px" width="30px"
            className="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
          >
            <g className="fa-group">
              <path
                fill="currentColor"
                d="m256 133.609375c-67.484375 0-122.390625 54.90625-122.390625 122.390625s54.90625 122.390625 122.390625 122.390625 122.390625-54.90625 122.390625-122.390625-54.90625-122.390625-122.390625-122.390625zm0 214.183594c-50.613281 0-91.792969-41.179688-91.792969-91.792969s41.179688-91.792969 91.792969-91.792969 91.792969 41.179688 91.792969 91.792969-41.179688 91.792969-91.792969 91.792969zm0 0"
                className="fa-secondary" 
              ></path>
              <path
                fill="currentColor"
                d="m499.953125 197.703125-39.351563-8.554687c-3.421874-10.476563-7.660156-20.695313-12.664062-30.539063l21.785156-33.886719c3.890625-6.054687
                 3.035156-14.003906-2.050781-19.089844l-61.304687-61.304687c-5.085938-5.085937-13.035157-5.941406-19.089844-2.050781l-33.886719 21.785156c-9.84375-5.003906-20.0625-9.242188-30.539063-12.664062l-8.554687-39.351563c-1.527344-7.03125-7.753906-12.046875-14.949219-12.046875h-86.695312c-7.195313 0-13.421875 5.015625-14.949219 12.046875l-8.554687 39.351563c-10.476563 3.421874-20.695313 7.660156-30.539063 12.664062l-33.886719-21.785156c-6.054687-3.890625-14.003906-3.035156-19.089844 2.050781l-61.304687 61.304687c-5.085937 5.085938-5.941406 13.035157-2.050781
                 19.089844l21.785156 33.886719c-5.003906 9.84375-9.242188 20.0625-12.664062 30.539063l-39.351563 8.554687c-7.03125 1.53125-12.046875 7.753906-12.046875 14.949219v86.695312c0 7.195313 5.015625 13.417969 12.046875 14.949219l39.351563 8.554687c3.421874 10.476563 7.660156 20.695313 12.664062 30.539063l-21.785156 33.886719c-3.890625 6.054687-3.035156 14.003906 2.050781 19.089844l61.304687 61.304687c5.085938 5.085937 13.035157 5.941406 19.089844 2.050781l33.886719-21.785156c9.84375 5.003906 20.0625 9.242188 30.539063 12.664062l8.554687 39.351563c1.527344 7.03125 7.753906 12.046875 14.949219
                  12.046875h86.695312c7.195313 0 13.421875-5.015625 14.949219-12.046875l8.554687-39.351563c10.476563-3.421874 20.695313-7.660156 30.539063-12.664062l33.886719 21.785156c6.054687 3.890625 14.003906 3.039063 19.089844-2.050781l61.304687-61.304687c5.085937-5.085938 5.941406-13.035157 2.050781-19.089844l-21.785156-33.886719c5.003906-9.84375 9.242188-20.0625 12.664062-30.539063l39.351563-8.554687c7.03125-1.53125 12.046875-7.753906 12.046875-14.949219v-86.695312c0-7.195313-5.015625-13.417969-12.046875-14.949219zm-18.550781 89.3125-36.082032 7.84375c-5.542968 1.207031-9.964843 5.378906-11.488281 10.839844-3.964843 14.222656-9.667969 27.976562-16.949219 40.878906-2.789062
                   4.941406-2.617187 11.019531.453126 15.792969l19.980468 31.078125-43.863281 43.867187-31.082031-19.980468c-4.773438-3.070313-10.851563-3.242188-15.789063-.453126-12.90625 7.28125-26.660156 12.984376-40.882812 16.949219-5.460938 1.523438-9.632813 5.945313-10.839844 11.488281l-7.84375 36.082032h-62.03125l-7.84375-36.082032c-1.207031-5.542968-5.378906-9.964843-10.839844-11.488281-14.222656-3.964843-27.976562-9.667969-40.878906-16.949219-4.941406-2.789062-11.019531-2.613281-15.792969.453126l-31.078125 19.980468-43.863281-43.867187 19.976562-31.078125c3.070313-4.773438
                    3.246094-10.851563.457032-15.792969-7.28125-12.902344-12.984375-26.65625-16.953125-40.878906-1.523438-5.460938-5.941407-9.632813-11.484375-10.839844l-36.085938-7.84375v-62.03125l36.082032-7.84375c5.542968-1.207031 9.964843-5.378906 11.488281-10.839844 3.964843-14.222656 9.667969-27.976562 16.949219-40.878906 2.789062-4.941406 2.617187-11.019531-.453126-15.792969l-19.980468-31.078125 
                   43.863281-43.867187 31.082031 19.980468c4.773438 3.070313 10.851563 3.242188 15.789063.453126 12.90625-7.28125 26.660156-12.984376 40.882812-16.949219 5.460938-1.523438 9.632813-5.945313 10.839844-11.488281l7.84375-36.082032h62.03125l7.84375 36.082032c1.207031 5.542968 5.378906 9.964843 10.839844 11.488281 14.222656 3.964843 27.976562 9.667969 40.878906 16.949219 4.941406 2.789062 11.019531 2.613281 15.792969-.453126l31.078125-19.980468 43.863281
                 43.867187-19.976562 31.078125c-3.070313 4.773438-3.246094 10.851563-.457032 15.792969 7.285156 12.902344 12.984375 26.65625 16.953125 40.878906 1.523438 5.460938 5.941407 9.632813 11.484375 10.839844l36.085938 7.84375zm0 0"
                className="fa-primary"
              ></path>
            </g>
          </svg>
          </Link>
   
      </li>





      <li className="navc-item">
      <Link to="/" className="navc-link">
       
  <svg height="30px" id="Layer_1" version="1.1" viewBox="0 0 16 16" width="30px"
  fill="currentColor" >
  <path d="M15.45,7L14,5.551V2c0-0.55-0.45-1-1-1h-1c-0.55,0-1,0.45-1,1v0.553L9,0.555C8.727,0.297,8.477,0,8,0S7.273,0.297,7,0.555
    L0.55,7C0.238,7.325,0,7.562,0,8c0,0.563,0.432,1,1,1h1v6c0,0.55,0.45,1,1,1h3v-5c0-0.55,0.45-1,1-1h2c0.55,0,1,0.45,1,1v5h3  c0.55,0,1-0.45,1-1V9h1c0.568,0,1-0.437,1-1C16,7.562,15.762,.325,15.45" 
  className="fa-secondary"></path></svg>

          <span className="link-text">Home</span>
    </Link>
      </li>







<li className="navc-item">
<Link to="/Blog" className="navc-link">
<svg version="1.1" id="Capa_1"
   viewBox="0 0 317.037 317.037" height="30px" width="30px"
   fill="currentColor">
<g>
  <path d="M197.234,47.994h-67.537c0,95.635-11.786,124.19-24.024,140.687
    c-12.238,16.491,4.531,27.924,4.531,27.924c25.373,15.708,41.429,71.453,44.698,83.691c-3.726,2.763-6.168,7.152-6.168,12.145
    c0,7.419,5.336,13.565,12.368,14.876l-0.076-24.595l-0.016-5.151l-0.261-85.66l-14.191-14.191h33.82l-14.185,14.185l0.261,85.638
    l0.016,5.183l0.076,24.612c7.103-1.256,12.51-7.43,12.51-14.892c0-5.319-2.752-9.981-6.897-12.684
    c9.159-36.447,32.248-69.446,45.927-82.244c14.049-13.146,5.439-24.356,5.439-24.356
    C194.965,147.045,197.234,47.994,197.234,47.994z" className="fa-secondary"/>
  <path  d="M124.35,0v12.689h-8.039c-7.636,0-13.826,6.19-13.826,13.826s6.19,13.826,13.826,13.826h95.124
    c7.636,0,13.826-6.19,13.826-13.826s-6.19-13.826-13.826-13.826H199.48V0H124.35z" className="fa-secondary"/>
</g>

</svg>

          <span className="link-text">Blog</span>
</Link>
      </li>








 <li className="navc-item">
<Link to="/Projects" className="navc-link">
  <svg height="30px" id="Layer_1" version="1.1" width="30px"
  fill="currentColor"
   viewBox="0 0 482.13 482.13">
<g>
  <g>
    <path d="M475.266,126.603c-0.001,0-0.001,0-0.002,0l-0.016-0.016l-31.12-4.44c-4.374-0.623-7.415-4.674-6.792-9.048
      c0.299-2.102,1.422-3.998,3.12-5.272l25.152-18.864c3.535-2.651,4.251-7.665,1.6-11.2l-28.8-38.4
      c-2.651-3.535-7.665-4.251-11.2-1.6l-25.144,18.864c-3.52,2.671-8.538,1.983-11.209-1.537c-1.297-1.709-1.853-3.868-1.543-5.991
      l4.448-31.12c0.627-4.374-2.41-8.427-6.783-9.055c-0.003,0-0.006-0.001-0.009-0.001L339.48,2.139
      c-4.373-0.628-8.428,2.408-9.056,6.782c0,0.001,0,0.001,0,0.002l-4.448,31.12c-0.623,4.374-4.674,7.415-9.048,6.792
      c-2.102-0.299-3.998-1.422-5.272-3.12L292.8,18.563c-2.651-3.535-7.665-4.251-11.2-1.6l-24.04,18.04
      c-10.876-1.156-21.844-1.156-32.72,0C153.614,43.144,97.759,99.946,90.816,171.299c-0.216,2.264-0.296,4.512-0.408,6.76h-2.4
      c-3.796,0.007-7.065,2.681-7.824,6.4l-5.528,26.288l-22.144-15.272c-3.178-2.193-7.47-1.802-10.2,0.928l-24,24
      c-2.726,2.729-3.116,7.015-0.928,10.192l15.2,22.104l-26.296,5.528c-3.678,0.806-6.296,4.067-6.288,7.832v32
      c-0.002,3.784,2.649,7.052,6.352,7.832l26.296,5.528l-15.2,22.104c-2.188,3.177-1.798,7.463,0.928,10.192l24,24
      c2.73,2.73,7.022,3.121,10.2,0.928l22.104-15.24l5.528,26.296c0.759,3.719,4.028,6.393,7.824,6.4h32
      c3.784,0.002,7.052-2.649,7.832-6.352l5.528-26.296l22.104,15.2c3.177,2.188,7.463,1.798,10.192-0.928l12.424-12.464v22.8
      c0.036,11.381,6.133,21.88,16,27.552v44.448c0,17.673,14.327,32,32,32h32c17.673,0,32-14.327,32-32v-44.448
      c9.867-5.672,15.964-16.171,16-27.552v-31.664c0.217-13.323,7.187-25.623,18.504-32.656c6.639-4.307,12.935-9.122,18.832-14.4
      l-10.672-11.92c-5.275,4.728-10.911,9.037-16.856,12.888c-15.885,9.97-25.609,27.334-25.808,46.088v31.664c0,8.837-7.163,16-16,16
      h-64c-8.837,0-16-7.163-16-16v-32.328c-0.185-18.399-9.756-35.428-25.376-45.152c-63.407-39.842-82.511-123.541-42.669-186.948
      c24.72-39.341,67.862-63.286,114.325-63.452c-0.119,0.285-0.224,0.576-0.312,0.872c-0.297,2.1,0.253,4.233,1.528,5.928
      l18.856,25.144c1.988,2.573,2.179,6.107,0.48,8.88c-1.636,2.79-4.799,4.312-8,3.848l-31.12-4.448
      c-4.374-0.627-8.427,2.41-9.055,6.783c0,0.003-0.001,0.006-0.001,0.009l-6.792,47.512c-0.627,4.373,2.41,8.427,6.783,9.055
      c0.003,0,0.006,0.001,0.009,0.001l31.12,4.448c4.374,0.623,7.415,4.674,6.792,9.048c-0.299,2.102-1.422,3.998-3.12,5.272
      l-25.144,18.856c-3.535,2.651-4.251,7.665-1.6,11.2l28.8,38.4c2.651,3.535,7.665,4.251,11.2,1.6l25.152-18.856
      c3.538-2.646,8.552-1.923,11.198,1.616c1.266,1.693,1.811,3.819,1.514,5.912l-4.44,31.12c-0.628,4.373,2.408,8.428,6.782,9.056
      c0.001,0,0.001,0,0.002,0l47.52,6.792c0.374,0.048,0.751,0.072,1.128,0.072c3.983,0.005,7.362-2.921,7.928-6.864l4.44-31.12
      c0.623-4.374,4.674-7.415,9.048-6.792c2.102,0.299,3.998,1.422,5.272,3.12l18.864,25.144c2.651,3.535,7.665,4.251,11.2,1.6
      l38.4-28.8c3.535-2.651,4.251-7.665,1.6-11.2l-18.856-25.144c-1.979-2.577-2.17-6.105-0.48-8.88c1.601-2.827,4.793-4.359,8-3.84
      l31.12,4.44c4.373,0.628,8.428-2.409,9.056-6.782c0-0.001,0-0.001,0-0.002l6.784-47.52
      C482.676,131.285,479.64,127.231,475.266,126.603z M210.112,410.059h64v16h-64V410.059z M210.112,442.059h64v8
      c0,8.837-7.163,16-16,16h-32c-8.837,0-16-7.163-16-16V442.059z M146.424,304.099c4.368,3.572,8.932,6.898,13.672,9.96
      c7.559,4.777,13.249,12.004,16.12,20.472l-17.16,17.128l-16.616-11.456c-7.276-5.014-17.239-3.18-22.254,4.096
      c-1.202,1.745-2.046,3.711-2.482,5.784l-4.192,19.976H94.496l-4.192-19.944c-1.82-8.647-10.305-14.182-18.952-12.362
      c-2.073,0.436-4.039,1.28-5.784,2.482l-16.624,11.424l-14.576-14.544l11.448-16.608c5.019-7.273,3.191-17.237-4.082-22.256
      c-1.746-1.205-3.714-2.051-5.79-2.488L16,291.563v-19.008l19.944-4.2c8.644-1.833,14.166-10.326,12.333-18.971
      c-0.435-2.051-1.268-3.996-2.453-5.725l-11.456-16.656l14.576-14.544l16.632,11.456c7.279,5.009,17.242,3.169,22.251-4.11
      c1.2-1.743,2.042-3.707,2.477-5.778l1.4-6.656c1.282,9.218,3.405,18.298,6.344,27.128c-26.323,3.138-45.118,27.022-41.98,53.345
      c3.138,26.323,27.022,45.118,53.345,41.98C125.259,327.934,139.13,318.293,146.424,304.099z M134,292.827
      c-5.974,16.605-24.277,25.223-40.882,19.25c-16.605-5.974-25.223-24.277-19.25-40.882c4.57-12.703,16.631-21.164,30.131-21.136
      c0.104,0,0.2,0,0.304,0C111.66,265.922,121.707,280.391,134,292.827z M460.512,172.971l-23.2-3.312
      c-13.122-1.874-25.278,7.245-27.152,20.366c-0.9,6.301,0.74,12.702,4.56,17.794l14.056,18.744l-25.6,19.2l-14.064-18.736
      c-7.952-10.605-22.995-12.756-33.599-4.804c-5.092,3.818-8.459,9.503-9.361,15.804l-3.24,23.232l-31.672-4.528l3.312-23.2
      c1.879-13.121-7.235-25.281-20.356-27.159c-6.305-0.903-12.709,0.737-17.804,4.559l-18.744,14.056l-19.2-25.6l18.736-14.064
      c10.605-7.952,12.756-22.995,4.804-33.599c-3.818-5.092-9.503-8.459-15.804-9.361l-23.2-3.312l4.528-31.672l23.2,3.312
      c13.121,1.879,25.281-7.235,27.159-20.356c0.903-6.305-0.737-12.709-4.559-17.804l-14.104-18.76l25.6-19.2l14.064,18.744
      c7.952,10.605,22.995,12.756,33.599,4.804c5.092-3.818,8.459-9.503,9.361-15.804l3.312-23.2l31.672,4.52l-3.312,23.2
      c-1.874,13.122,7.244,25.278,20.366,27.152c6.301,0.9,12.702-0.74,17.794-4.56l18.744-14.056l19.2,25.6l-18.744,14.064
      c-10.605,7.952-12.756,22.995-4.804,33.599c3.818,5.092,9.503,8.459,15.804,9.361l23.2,3.312L460.512,172.971z" className="fa-secondary"/>
  </g>
</g>
<g>
  <g>
    <path d="M344,76.171c-35.346,0-64,28.654-64,64c0,35.346,28.654,64,64,64c35.346,0,64-28.654,64-64
      C407.96,104.841,379.33,76.21,344,76.171z M344,188.171c-26.51,0-48-21.49-48-48s21.49-48,48-48c26.51,0,48,21.49,48,48
      C391.969,166.668,370.497,188.14,344,188.171z" className="fa-primary"/>
  </g>
</g>
<g>
  <g>
    <rect x="234.112" y="354.059" width="16" height="24" className="fa-primary"/>
  </g>
</g>
<g>
  <g>
    <path d="M207.12,275.491c-49.385-19.292-73.78-74.966-54.487-124.351c7.476-19.136,20.893-35.369,38.279-46.313l-8.552-13.536
      c-52.336,32.971-68.035,102.126-35.064,154.462c12.769,20.269,31.701,35.91,54.016,44.626c19.752,7.568,32.795,26.528,32.8,47.68
      h16C250.137,310.307,233.035,285.418,207.12,275.491z" className="fa-primary"/>
  </g>
</g>
<g>
  <g>
    <rect x="394.112" y="298.059" width="56" height="16" className="fa-primary"/>
  </g>
</g>
<g>
  <g>
    <rect x="402.113" y="334.822" transform="matrix(0.6401 -0.7683 0.7683 0.6401 -133.6437 446.8239)" width="16" height="62.48" className="fa-primary"/>
  </g>
</g>
<g>
  <g>
    <rect x="346.112" y="362.059" width="16" height="56" className="fa-primary"/>
  </g>
</g>
<g>
  <g>
    <rect x="9.128" y="130.058" transform="matrix(0.9701 -0.2425 0.2425 0.9701 -32.2251 14.3351)" width="65.968" height="15.992" className="fa-primary"/>
  </g>
</g>
<g>
  <g>
    <rect x="46.111" y="35.657" transform="matrix(0.5812 -0.8137 0.8137 0.5812 -34.3557 73.3688)" width="15.992" height="68.816" className="fa-primary"/>
  </g>
</g>
<g>
  <g>
    <rect x="97.938" y="1.024" transform="matrix(0.9688 -0.2477 0.2477 0.9688 -5.133 27.2971)" width="16" height="66.056" className="fa-primary"/>
  </g>
</g>
</svg>


          <span className="link-text">Projects</span>
</Link>
      </li>




      <li className="navc-item">
    <a href={pdf} className="navc-link">
 <svg version="1.1" id="Capa_1"
   viewBox="0 0 512 512"  height="30px" width="30px"
   fill="currentColor">
<g>
  <g>
    <g>
      <path  d="M464.217,114.566L352.913,3.261C350.831,1.169,348.004,0,345.043,0H77.913
        c-18.41,0-33.391,14.982-33.391,33.391v445.217c0,18.41,14.982,33.391,33.391,33.391h356.174c18.399,0,33.38-14.97,33.391-33.369
        c0-0.011,0-0.011,0-0.022V122.435C467.478,119.485,466.31,116.647,464.217,114.566z M133.565,133.565V89.043h178.087v44.522
        H133.565z M445.217,478.609c0,6.144-4.986,11.13-11.13,11.13H77.913c-6.133,0-11.13-4.986-11.13-11.13V33.391
        c0-6.133,4.998-11.13,11.13-11.13h233.739v44.522H122.435c-6.144,0-11.13,4.986-11.13,11.13v66.783
        c0,6.144,4.986,11.13,11.13,11.13h200.348h122.435V478.609z M445.217,133.565H333.913V22.261h6.522l104.782,104.782V133.565z" className="fa-secondary"/>
      <path  d="M122.435,222.609H256c6.144,0,11.13-4.986,11.13-11.13s-4.986-11.13-11.13-11.13H122.435
        c-6.144,0-11.13,4.986-11.13,11.13S116.291,222.609,122.435,222.609z" className="fa-secondary"/>
      <path d="M122.435,356.174H256c6.144,0,11.13-4.975,11.13-11.13c0-6.155-4.986-11.13-11.13-11.13H122.435
        c-6.144,0-11.13,4.975-11.13,11.13C111.304,351.199,116.291,356.174,122.435,356.174z" className="fa-secondary"/>
      <path  d="M111.304,278.261c0,6.155,4.986,11.13,11.13,11.13h267.13c6.155,0,11.13-4.975,11.13-11.13
        c0-6.155-4.975-11.13-11.13-11.13h-267.13C116.291,267.13,111.304,272.106,111.304,278.261z" className="fa-secondary"/>
      <path  d="M389.565,400.696h-267.13c-6.144,0-11.13,4.975-11.13,11.13s4.986,11.13,11.13,11.13h267.13
        c6.155,0,11.13-4.975,11.13-11.13S395.72,400.696,389.565,400.696z" className="fa-secondary"/>
    </g>
  </g>
</g>

</svg>
          <span className="link-text">C.V.</span>
</a>
      </li>






      <li className="navc-item navc-link" onClick={e=>setTheme(theme.mode==='dark'?{mode:'light'}:{mode:'dark'})}>

          <svg
            className="colorModeDark"
            height="30px"
            width="30px"
            viewBox="0 0 512 512"
         
          >
            <g class="fa-group">
              <path
                fill="currentColor"
                d="M502.42 240.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.41-94.8a17.31 17.31 0 0 0-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4a17.31 17.31 0 0 0 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.41-33.5 47.3 94.7a17.31 17.31 0 0 0 31 0l47.31-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3a17.33 17.33 0 0 0 .2-31.1zm-155.9 106c-49.91 49.9-131.11 49.9-181 0a128.13 128.13 0 0 1 0-181c49.9-49.9 131.1-49.9 181 0a128.13 128.13 0 0 1 0 181z"
                class="fa-secondary"
              ></path>
              <path
                fill="currentColor"
                d="M352 256a96 96 0 1 1-96-96 96.15 96.15 0 0 1 96 96z"
                class="fa-primary"
              ></path>
            </g>
          </svg>
                    <span className="link-text colorModeDark coloModeText">Light</span>
          <svg
            className="colorModeLight"
            height="30px"
            width="30px"

            viewBox="0 0 512 512"
   
          >
            <g class="fa-group">
              <path
                fill="currentColor"
                d="M320 32L304 0l-16 32-32 16 32 16 16 32 16-32 32-16zm138.7 149.3L432 128l-26.7 53.3L352 208l53.3 26.7L432 288l26.7-53.3L512 208z"
                class="fa-secondary"
              ></path>
              <path
                fill="currentColor"
                d="M332.2 426.4c8.1-1.6 13.9 8 8.6 14.5a191.18 191.18 0 0 1-149 71.1C85.8 512 0 426 0 320c0-120 108.7-210.6 227-188.8 8.2 1.6 10.1 12.6 2.8 16.7a150.3 150.3 0 0 0-76.1 130.8c0 94 85.4 165.4 178.5 147.7z"
                class="fa-primary"
              ></path>
            </g>
          </svg>
          <span className="link-text colorModeLight coloModeText">Dark</span>


      </li>










    </ul>
  </nav>

</body>
</>

</ThemeProvider>
</header>
  );
}

export default Header;
 